.about {
    background-color: white;
    margin: auto;
    padding: 1rem 1rem 0 1rem;
    width: 100%;
}

.about .container {
    width: 1240px;
    margin: auto;
    grid-template-columns: repeat(2, 1fr);
}


.about h2 {
    font-size: 3rem;
    color: rgb(155, 11, 11);
}

.about .col-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

.about .col-2 p:first-of-type {
    margin-top: 2rem;
    margin-bottom: 0.5rem ;
    font-weight: 400;
}
.about .col-2 p:nth-of-type(2){
    margin: 0.5rem 0;
    font-weight: 400;
}
.about .col-2 p:nth-of-type(3){
    margin: 0.5rem 0;
    font-weight: 400;
}
.about .col-2 p:nth-of-type(4){
    margin: 0.5rem 0;
    font-weight: 400;
}


@media screen and (max-width:940px) {
    .about .container {
        grid-template-columns: 1fr;
    }

    .about .container {
        width: 100%;
    }


    .about .container .col-2 {
        padding: 0;
    }
    .about h2 {
        font-size: 2rem;
        color: rgb(155, 11, 11);
    }
        
    .about .col-2 p:first-of-type {
        font-size: small;
        margin-top: 1rem;
        margin-bottom: 0.5rem ;
        font-weight: 400;
    }
    .about .col-2 p:nth-of-type(2){
        font-size: small;
        margin: 0.5rem 0;
        font-weight: 400;
    }
    .about .col-2 p:nth-of-type(3){
        font-size: small;
        margin: 0.5rem 0;
        font-weight: 400;
    }
    .about .col-2 p:nth-of-type(4){
        font-size: small;
        margin: 0.5rem 0;
        font-weight: 400;
    }


}