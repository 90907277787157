.contact {
    background-color: rgb(155, 11, 11);
    margin: auto;
    padding: 1rem 1rem 0 1rem;
    width: 100%;
    height: 100%;
}

.contact .container {
    width: 1240px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.6rem;
}

.contact .info{
    display: grid;
    padding-bottom: 15px;
}


.contact h2 {
    font-size: 3rem;
    text-align: center;
    color: white;
}

.contact .col-2 {
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h3{
    padding-top: 10px;
    padding-bottom: 5px;
    color: whitesmoke;
    font-weight: 500;
    font-size: large;

}

.contact .info p{
    color: white;
    font-weight: 300;
    font-size: small;
}

@media screen and (max-width:940px) {
    .contact .container {
        width: 100%;
        grid-template-columns: 1fr;
        padding: 0;
    }
    .contact h2{
        font-size: 2rem;
    }
    h3{
        font-size: medium;
    }
    .contact .info p{
        font-size: xx-small;
    }
}