.home {
    background-color: var(--overlay);
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;
}

.home:before {
    content: '';
    background: url('./images/mozzarella.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.home .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    margin: auto;
    padding: 1rem;
}

.home .content p:first-child{
    margin-top: 30vh;
    margin-bottom: 0;
    text-align: center;
    font-size: 4rem;
    font-weight: 800;
}

.home .content p:nth-child(2){
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    font-style: italic;
}

.home .button {
    margin: auto;
    position: relative;
    width: 300px;
    background-color: rgb(155, 11, 11);
    border: 1px solid rgb(155, 11, 11);
}

.home .button a{
    margin: auto;
    position: relative;
    color: white;
    width: 300px;
    border: 1px solid (155, 11, 11);
}

.home .button:hover {
    background-color: white;
    color: rgb(155, 11, 11);
    border-width: thick;
}
.home .button:hover a{
    background-color: white;
    color: rgb(155, 11, 11);
    border-width: thick;
}



@media screen and (max-width:930px) {
    .home .content p:first-child{
        text-align: center;
        font-size: 2rem;
        font-weight: 800;
    }
    .home .content p:nth-child(2) {
        font-size: 0.8rem;
    }
}