.header {
    position: fixed;
    height: 70px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    background-color:white;
    
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}

.header .nav-menu a {
    color: black;
}

.header .navbar img {
    width: 120px;
    height: 80px;
}

.header .nav-menu {
    display: flex;
}

.header .nav-item {
    padding: 1.5rem;
    font-weight: 600;
    font-size: medium;
}

.header .nav-item button{
    background-color: transparent;
    margin: 2px;
    font-size: medium;
    border: 3px solid transparent;
    color: rgb(155, 11, 11);
    font-weight: 600;
}

.header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

button:hover {
    padding-bottom: 2px;
    border-bottom: 3px solid var(--secondary-color);
}

.hamburger {
    display: none;
    color: black;
}



@media screen and (max-width:940px) {
    .header {
        max-width: 100%;
        background-color: white;
       /* background-image: url("images/navbar.png");
        background-repeat: no-repeat;
        background-size: cover;*/
    }
    .header .navbar {
        max-width: 100%;
    }

    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: column;
        background-color: white;
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .4s;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        /*margin: 1.5rem 0;*/
        padding: 0.5rem;
        font-size: larger;
    }

    .header .navbar img {
        width: 120px;
        height: 70px;
    }
    
}