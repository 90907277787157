.machines {
    background-color: rgba(245, 245, 245, 0.908);
    margin: auto;
    padding: 1rem 1rem 0 1rem;
    width: 100%;
}

.machines .content {
    width: 1240px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    
}

.machines h2 {
    font-size: 3rem;
    color: rgb(155, 11, 11);
    padding: 2rem;
}


.machines .imageList {
    width: 1240px;
    padding: 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 50px;
    text-align: center;
    
}
.machines .imageList img {
     width: 300px;
     height: 300px;
     border-radius: 10px;
}
.machines .imageList p{
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 800;
    font-size: 1.3rem;
}

.machines .imageList img:hover {
    opacity: 0.5;
  }

  
.machines .imageList .longImg img{
    width: 130px;
    height: 300px;
    border-radius: 10px;
}  
@media screen and (max-width:940px) {
    .machines .imageList {
        display: block;
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
    }
    .machines .content {
        width: 100%;
        padding: 0;
    }
     .machines .imageList p{
        padding-bottom: 2rem;
     }
     .machines h2 {
        font-size: 2rem;
        color: rgb(155, 11, 11);
        padding: 2rem;
    }
    
}